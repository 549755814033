import { ActionReducerMap } from '@ngrx/store';

// Models
import { FiltersState } from './models/filters_state';

// Reducers
import { filterReducer } from './reducers/app.reducer';

export interface AppStateModel {
  filtersState: FiltersState
}

export const ROOT_REDUCERS: ActionReducerMap<AppStateModel> = {
  filtersState: filterReducer
}
