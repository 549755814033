import { createAction, props } from "@ngrx/store";

// Models
import { TypeFilter } from "../../modules/shared/models/enums";
import { SelectItem } from "../../modules/shared/models/select_item";
import { Corporation } from "../../modules/shared/models/corporation";
import { FiltersRequest } from "src/app/modules/shared/models/filters_request";

export const setCorporations = createAction(
  "[App] set corporations",
  props<{ corporations: Corporation[] }>()
);

export const setFilterSelected = createAction(
  "[App] set filter selected",
  props<{ item: SelectItem; typeFilter: TypeFilter }>()
);

export const resetData = createAction("[App] reset data");

export const getFilters = createAction(
  "[App] get filters",
  props<{ filters: FiltersRequest; typeFilter: TypeFilter }>()
);
