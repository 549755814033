import { createReducer, on } from '@ngrx/store';

// Actions
import * as actions from 'src/app/state/actions/app.actions';

// Models
import { FiltersState } from '../models/filters_state';
import { Filters } from 'src/app/state/models/filters';
import { TypeFilter } from 'src/app/modules/shared/models/enums';
import { defaultItem, SelectItem } from 'src/app/modules/shared/models/select_item';

export const initialStateFilters: FiltersState = {
  corporations: [],
  filters: {
    zone: {...defaultItem},
    town: {...defaultItem},
    point: {...defaultItem},
    table: {...defaultItem},
    commission: {...defaultItem},
    corporation: {...defaultItem},
    departament: {...defaultItem}
  }
};

export const filterReducer = createReducer(
  initialStateFilters,
  on(actions.setCorporations, (state, {corporations}) => {
    return {
      ...state,
      corporations
    }
  }),   
  on(actions.setFilterSelected, (state, {item, typeFilter}) => {
    const _filters: Filters = setFilterSelected(state.filters, item, typeFilter);
    return {
      ...state,
      filters: _filters
    }
  }),
  on(actions.resetData, () => {
    return {...initialStateFilters}
  }),  
);

const setFilterSelected = (stateFilters: Filters, item: SelectItem, typeFilter: TypeFilter): Filters => {
  switch (typeFilter) {
    case TypeFilter.corporation:
      return {...stateFilters, corporation: item};

    case TypeFilter.departament:
      return {...stateFilters, departament: item, town: {...defaultItem}, commission: {...defaultItem}, point: {...defaultItem}, zone: {...defaultItem}, table: {...defaultItem}};

    case TypeFilter.town:
      return {...stateFilters, town: item, commission: {...defaultItem}, point: {...defaultItem}, zone: {...defaultItem}, table: {...defaultItem}};

    case TypeFilter.commission:
      return {...stateFilters, commission: item, point: {...defaultItem}, zone: {...defaultItem}, table: {...defaultItem}};

    case TypeFilter.zone:
      return {...stateFilters, zone: item, point: {...defaultItem}, table: {...defaultItem}};

    case TypeFilter.point:
      return {...stateFilters, point: item, table: {...defaultItem}};      

    case TypeFilter.table:
      return {...stateFilters, table: item};              
  
    default:
      return stateFilters;
  }
}

