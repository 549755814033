export interface SelectItem {
  id: string;
  label: string;
  subItems?: boolean;
}

export const defaultItem: SelectItem = { id: "", label: "" };
export const globalItem: SelectItem = {
  id: "1",
  label: "Nacional",
  subItems: true,
};
